import type { ITpltBaseAirtableItem, ITpltBaseItem } from "~/js/network/airtable-tpl-base";
import { BaseApi } from "./baseApi";
import { fetchAirTablePagination } from "./fetchAirTablePagination";
import { axiosClient } from "~/js/network/axiosClient";
import { config } from "~/js/network/network-handler";
import type { RouteDataFuncArgs } from "@solidjs/router";
import { queryClient } from "./queryClient";
import type { Record } from "airtable";
import { AirtableProxiApi } from "./AirtableProxyApi";
import { WorkspaceApi } from "~/solidJs/entities/WorkspaceListEntity/api/WorkspaceApi";
import { getUserStore } from "~/solidJs/entities/HeaderMain/models/userStore";
import type { AuthRouterContext } from "~/solidJs/app/MainApp/models";
export class HomePageApi extends BaseApi {
	static processTemplates(records: Record<ITpltBaseAirtableItem>[]) {
		const TPLBase: ITpltBaseItem[] = [];
		for (const record of records) {
			const item = {} as ITpltBaseItem;
			if (record.fields["Env"] !== config.ENV) continue;
			item.active = record.fields["Active"];
			item.serviceId = record.fields["ID"];
			item.name = record.fields["Name"];
			item.disabledText = record.fields["disabledText"];
			item.type = record.fields["type"];
			item.cover = "";
			item.category = record.fields["category"] || [];
			item.preview = record.fields.preview?.[0]?.url;
			item.cover = record.fields.CoverCdn;
			item.coverHomePage = record.fields.CoverHomePageCdn;
			item.description = record.fields["Description"];
			item.disabledBanner = record.fields.disabledBannerCdn;
			item.groupName = record.fields["groupName"];
			item.searchTags = record.fields.searchTags;
			const stickersFromAir = record.fields["stickers"];
			const disabledGroups = record.fields["disabledGroups"];
			if (stickersFromAir) {
				try {
					item.stickers = JSON.parse(record.fields["stickers"]);
				} catch (e) {
					console.log(e);
				}
			}
			if (disabledGroups) {
				try {
					item.disabledGroups = JSON.parse(disabledGroups);
				} catch (e) {
					console.log(e);
				}
			}
			item.isPremium = record.fields["IsPremium"];

			TPLBase.push(item);
		}
		return TPLBase;
	}
	static key_airtableTemplatesAndTask = "airtableTemplatesAndTasks";
	static async getAirtableTemplatesAndTasks(): Promise<ITpltBaseItem[]> {
		const { records } = await fetchAirTablePagination<ITpltBaseAirtableItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "tbl3dGSFNeImtNRMz",
			view: "Dev",
			axiosInstance: axiosClient.axiosClient
		});

		return this.processTemplates(records);
	}
	static async getAirtableTemplates() {
		const { records } = await fetchAirTablePagination<ITpltBaseAirtableItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "tbl3dGSFNeImtNRMz",
			view: "templates",
			axiosInstance: axiosClient.axiosClient
		});
		return this.processTemplates(records);
	}
	static async getAirtableTasks() {
		const { records } = await fetchAirTablePagination<ITpltBaseAirtableItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "tbl3dGSFNeImtNRMz",
			view: "tasks",
			axiosInstance: axiosClient.axiosClient
		});
		return this.processTemplates(records);
	}
	static airtableTemplatesQueryKey = "airtableTemplates";
	static airtableTasksQueryKey = "airtableTasks";
	static async loadHomePageData(params: RouteDataFuncArgs<AuthRouterContext>) {
		const user = getUserStore();
		queryClient.prefetchQuery({
			queryKey: [HomePageApi.airtableTemplatesQueryKey],
			queryFn: HomePageApi.getAirtableTemplates.bind(HomePageApi)
		});
		queryClient.prefetchQuery({
			queryKey: [HomePageApi.airtableTasksQueryKey],
			queryFn: HomePageApi.getAirtableTasks.bind(HomePageApi)
		});
		if (user) {
			await params.data.user;
			queryClient.prefetchQuery({
				queryKey: [WorkspaceApi.workspaceListLightKey],
				queryFn: WorkspaceApi.getWorkspaceLightList.bind(WorkspaceApi)
			});
		}

		queryClient.prefetchInfiniteQuery({
			queryKey: [AirtableProxiApi.onboardingFeedKey],
			queryFn: (props) =>
				AirtableProxiApi.fetchTransformOnboarding(
					props.pageParam.limit,
					props.pageParam.offset
				),
			initialPageParam: { limit: 10, offset: 0 },
			getNextPageParam: (lastPage) => ({
				offset: lastPage.offset,
				limit: lastPage.items.length > 0 ? 999 : 10
			}),
			pages: 2
		});
	}
}
