import { Show, createSignal } from "solid-js";
import { Portal } from "solid-js/web";

type ToastMessage = {
	title: string;
	timeout: number;
};

const [getToastStore, setToastStore] = createSignal<ToastMessage | null>(null);

let activeTimeout: NodeJS.Timeout;

export const showToastMessage = (msg: ToastMessage) => {
	clearTimeout(activeTimeout);
	activeTimeout = setTimeout(() => {
		setToastStore(null);
	}, msg.timeout);
	setToastStore(msg);
};

export const WorkspaceToaster = () => {
	return (
		<Portal>
			<div class="absolute left-1/2 -translate-x-1/2 bottom-6 z-[10]">
				<Show when={getToastStore()} keyed>
					{(msg) => <div class="rounded-xl text-xs bg-[#2E2D2D] p-4 text-white">{msg.title}</div>}
				</Show>
			</div>
		</Portal>
	);
};
