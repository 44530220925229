import { createSignal } from "solid-js";
import { sessionCounter } from "~/solidJs/shared";
type ShowChatGpt = {
	assistaint: boolean;
	dialogue: boolean;
};
export const ChatGptModes = {
	Close: {
		assistaint: false,
		dialogue: false
	}
} as const;
export const [getIsShowChatGpt, setIsShowChatGpt] = createSignal<ShowChatGpt>({
	assistaint: false,
	dialogue: true
});

export const getIsChatGptVisible = () => {
	return getIsShowChatGpt().assistaint || getIsShowChatGpt().dialogue;
};
