import { config } from "~/js/network/network-handler";

export const executeFcOnlyInTests = (callback: () => void) => {
	if (config.GRAYLOG_BUILD_NAME === "develop") {
		callback();
	}
};

/**
 * flags used to turn on/off some functionality via setting key to true in local storage
 */
export enum DebugFlags {
	/**
	 * certain functions vill execute only when this flags is true, used in tests envs
	 */
	MainDebug = "debug",
	/**
	 * force loading onboarding templates
	 */
	OnboardingDebug = "onboardingTemplateDebug",
	/**
	 * force open setup account flow
	 */
	SetupAccontDebug = "setupAccountDebug",
	/**
	 * hides modals pop over banners
	 * @depriceted
	 */
	ModalsDebug = "debugModals",
	/**
	 * disables loading init template for tests
	 */
	DisableInitTemplate = "disableInitTemplate"
}

/**
 * @description used to hide from users testing functionality
 * @returns
 */
export const isDebugFlag = (flag?: DebugFlags) => localStorage.getItem(flag || "debug") === "true";

export const executeFcOnlyOnFlag = (callback: () => void) => {
	if (isDebugFlag()) {
		callback();
	}
};
export const isModalsGlobalHidden = () => isDebugFlag(DebugFlags.ModalsDebug);
export const isInitTemplateGlobalHidden = () =>
	localStorage.getItem("disableInitTemplate") === "true";
