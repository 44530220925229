import { axiosClient } from "~/js/network/axiosClient";
import { BaseApi } from "./baseApi";
import type { IOnBoardingAirtableItem, IOnBoardingItem } from "~/js/network/airtable-tpl-base";
import type { IStyleAirtableBaseItem } from "~/js/network/airtable-styles-base";
import type { Record } from "airtable";
import type { IPromptBaseAirtableItem } from "~/js/network/airtable-prompt-base";
import type { IMlAirtableBaseItem, IMlBaseItem } from "~/js/network/airtable-ml-base";
import { config } from "~/js/network/network-handler";
import { fetchAirTablePagination } from "./fetchAirTablePagination";
export class AirtableProxiApi extends BaseApi {
	static baseUrl = BaseApi.baseUrl + "airtable/";

	static async getCustomCheckpointsPagination(limit: number, offset: number) {
		const { data } = await axiosClient.axiosClient.get<{
			records: Record<IStyleAirtableBaseItem>[];
			offset: number;
		}>(this.baseUrl + "custom-checkpoints", {
			params: {
				limit,
				offset
			}
		});
		return data;
	}
	static fetchAndTransformMlBaseKey = "fetchAndTransformMlBaseKey";
	static async fetchAndTransformMlBase() {
		return this.transformMlBase(await this.fetchMlBase());
	}
	static transformMlBase(records: Awaited<ReturnType<typeof AirtableProxiApi.fetchMlBase>>) {
		const items: IMlBaseItem[] = [];
		for (const record of records) {
			const item = {} as IMlBaseItem;
			item.globalId = record.fields["Global ID"];
			item.inputTypes = record.fields["Input"];
			item.outputTypes = record.fields["Output"];

			item.name = record.fields["Name"];
			item.description = record.fields["Description"];

			item.category = record.fields["Group of creator tasks"] || "Additional";
			item.tags = record.fields["Tags about usefulness"];
			item.neuralNetworks = record.fields["Neural network"];
			item.coverPictureUrl = record.fields.CoverCdn;
			const stickerLearnMoreUrl = record.fields["AI Library description"];
			item.stickerLearnMoreUrl = stickerLearnMoreUrl ? stickerLearnMoreUrl : null;
			item.notionPage = record.fields["Notion Page"];

			item.isNeedToVariate = record.fields["isNeedToVariate"];
			item.selectedPremiumPlan = record.fields["selectedPremiumPlan"];
			item.isNeedToRandomModels = record.fields["isNeedToRandomModels"];
			item.premiumBannerText = record.fields["premiumBannerHeader"];
			item.isNeedToSynchOutputs = record.fields["isNeedToSynchOutputs"];
			item.disabledPremiumText = record.fields["disabledPremiumText"];
			item.isNeedToVision = record.fields["isNeedToVision"];
			item.isNeedToAutoMask = record.fields["isNeedToAutoMask"];
			item.isNeedToPromptEnhance = record.fields["isNeedToPromptEnhance"];
			item.isNeedToVariationsPro = record.fields["isNeedToVariationsPro"];
			item.isNeedToPromptVariations = record.fields["isNeedToPromptVariations"];
			item.isNeedToReimagine = record.fields["isNeedToReimagine"];
			item.isNeedToRecolor = record.fields["isNeedToRecolor"];
			item.isNeedToRestyle = record.fields["isNeedToRestyle"];
			item.isNeedToCrop = record.fields["isNeedToCrop"];
			item.isNeedToDetailedUpscale = record.fields["isNeedToDetailedUpscale"];
			item.isNeedToUpscale = record.fields.isNeedToUpscale;
			item.isNeedToFaceRestore = record.fields["isNeedToFaceRestore"];
			item.isNeedToDownscale = record.fields["isNeedToDownscale"];
			item.searchTags = record.fields.searchTags;

			item.isNeedToGenPrompt = record.fields["isNeedToGenPrompt"];
			item.isNeedToFetchModels = record.fields["isNeedToFetchModels"];
			item.isNeedToFetchLoras = record.fields.isNeedToFetchLoras;
			item.isNeedToSvg = record.fields.isNeedToSvg;
			item.isNeedToOutPaint = record.fields["isNeedToOutPaint"];
			item.isNeedDalleGenerate = record.fields["isNeedDalleGenerate"];
			item.isNeedToZoomOut = record.fields["isNeedToZoomOut"];

			item.disabledBanner = record.fields.disabledBannerCdn;
			item.instructionGif = record.fields.instructionGifUrl;
			item.instructionText = record.fields["instructionText"];

			item.active = record.fields["Active"];
			item.modes = record.fields["Modes"];
			item.pplusmodels = record.fields[`PPlusDBModels_${config.GRAYLOG_BUILD_NAME}`];
			item.promptStyles = record.fields[`promptStyles_${config.GRAYLOG_BUILD_NAME}`];
			item.socketsToModify = record.fields[`SocketsToModify_${config.GRAYLOG_BUILD_NAME}`];
			item.isNeedToAddStyle = record.fields[`isNeedToAddStyle_${config.GRAYLOG_BUILD_NAME}`];
			item.isPremium = record.fields["isPremium"];
			item.medianTime = record.fields.medianTime;

			const socketDisplayJson =
				record.fields[`Sockets Display JSON_${config.GRAYLOG_BUILD_NAME}`];
			if (socketDisplayJson)
				try {
					item.socketsDisplay = JSON.parse(socketDisplayJson);
				} catch (e) {
					console.error("Sockets Display JSON parse error:", e);
				}
			items.push(item);
		}
		return items;
	}
	static async fetchMlBase() {
		const { records } = await fetchAirTablePagination<IMlAirtableBaseItem>({
			baseId: "app3F09VAe9P8yfA5",
			tableName: "tbl1t1dpFHzwaDEhp",
			view: "Dev",
			axiosInstance: axiosClient.axiosClient,
			fieldsToSelect: [
				"Title",
				"Global ID",
				"Input",
				"Output",
				"Name",
				"Description",
				"Group of creator tasks",
				"Tags about usefulness",
				"Neural network",
				"CoverCdn",
				"selectedPremiumPlan",
				"premiumBannerHeader",
				"AI Library description",
				"Notion Page",
				"isNeedToVariate",
				"isNeedToRandomModels",
				"isNeedToSynchOutputs",
				"isNeedToAutoMask",
				"isNeedToPromptEnhance",
				"isNeedToVariationsPro",
				"isNeedToPromptVariations",
				"isNeedToCrop",
				"disabledPremiumText",
				"isNeedDalleGenerate",
				"isNeedToReimagine",
				"isNeedToRecolor",
				"isNeedToRestyle",
				"isNeedToDetailedUpscale",
				"isNeedToUpscale",
				"isNeedToFaceRestore",
				"isNeedToDownscale",
				"searchTags",
				"isNeedToGenPrompt",
				"isNeedToFetchModels",
				"disabledBannerCdn",
				"Active",
				"some",
				"Modes",
				"isNeedToOutPaint",
				"isNeedToZoomOut",
				"isNeedToVision",
				"isPremium",
				`PPlusDBModels_${config.GRAYLOG_BUILD_NAME}`,
				`SocketsToModify_${config.GRAYLOG_BUILD_NAME}`,
				`promptStyles_${config.GRAYLOG_BUILD_NAME}`,
				`Sockets Display JSON_${config.GRAYLOG_BUILD_NAME}`,
				`isNeedToAddStyle_${config.GRAYLOG_BUILD_NAME}`,
				"instructionGifUrl",
				"instructionText",
				"isNeedToFetchLoras",
				"isNeedToSvg",
				"medianTime"
			]
		});
		return records;
	}
	static onboardingFeedKey = "onboardingFeed";
	static async getOnboardingFeedPagination(limit: number, offset: number) {
		const { data } = await axiosClient.axiosClient.get<{
			records: Record<IOnBoardingAirtableItem>[];
			offset: number;
		}>(this.baseUrl + "onboarding", {
			params: {
				limit,
				offset
			}
		});
		return data;
	}
	static async fetchTransformOnboarding(limit: number, offset: number) {
		const res = await this.getOnboardingFeedPagination(limit, offset);
		return this.transformOnboardingFeed(res);
	}
	static async transformOnboardingFeed(item: {
		records: Record<IOnBoardingAirtableItem>[];
		offset: number;
	}) {
		const { records, offset } = item;
		const items: IOnBoardingItem[] = [];
		for (const record of records) {
			const item = {} as IOnBoardingItem;
			item.nodeGlobalIdToAdd = record["fields"].nodeGlobalIdToAdd;
			try {
				item.paramsJson = JSON.parse(record["fields"].paramsJson);
			} catch (e) {
				console.log(e);
			}

			item.image = record["fields"].image[0].url;
			item.videoSrc = record.fields.videoSrc;
			item.templateId = record.fields.templateId;
			items.push(item);
		}
		return { items, offset };
	}
	static hintsPaginationKey = "hintsPaginationKey";
	static async getHintsPagination(limit: number, offset: number) {
		const { data } = await axiosClient.axiosClient.get<{
			records: Record<IPromptBaseAirtableItem>[];
			offset: number;
		}>(this.baseUrl + "hints", {
			params: {
				limit,
				offset
			}
		});
		return data;
	}
	static async getAirtableCachedResponseByLink<ResponseType>(link: string) {
		const { data } = await axiosClient.axiosClient.get<{ data: ResponseType }>(
			this.baseUrl + "proxy",
			{
				params: {
					link
				}
			}
		);
		return data;
	}
}
