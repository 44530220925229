import { type Component, type ComponentProps, onMount, splitProps } from "solid-js";
import { tv, type VariantProps } from "tailwind-variants";
import type { moleculesElementsSizes } from "~/solidJs/shared/helpers/types/MoleculesElementsSizes";

type inputVariants = VariantProps<typeof styles>["color"];
type InputOwnProps = {
	size?: moleculesElementsSizes;
	className?: string;
	variant?: inputVariants;
	disabled?: boolean;
};
const styles = tv({
	base: "w-full h-max rounded-md border border-solid text-current focus:outline-none focus:placeholder-opacity-0 invalid:outline invalid:outline-red-500 transition-opacity placeholder-gray-400",
	defaultVariants: {
		color: "default",
		size: "small"
	},
	variants: {
		color: {
			default: "bg-white border-gray-300",
			trainPageVariant: "bg-gray-800 border border-gray-300 text-white",
			outline:
				"border-border-gray-dark placeholder:text-text-secondary rounded-lg border bg-transparent",
			dropDownBtn:
				"bg-transparent border-transparent border-gray-300 border text-white w-max p-2 h-max"
		},
		size: {
			small: "px-4 py-2 text-2xs",
			medium: "px-4 py-3 text-sm",
			large: "px-6 py-4 text-2xs"
		},
		disabled: {
			true: "pointer-events-none opacity-50"
		}
	}
});
export type InputProps = InputOwnProps & Omit<ComponentProps<"input">, keyof InputOwnProps>;

export const Input: Component<InputProps> = (props) => {
	const [notNativeAttributes, others] = splitProps(props, [
		"class",
		"className",
		"size",
		"variant",
		"disabled",
		"ref"
	]);

	let inputRef: HTMLInputElement;

	onMount(() => {
		inputRef.addEventListener("keydown", (e) => e.stopPropagation());

		if (typeof notNativeAttributes.ref === "function") {
			notNativeAttributes.ref(inputRef);
		}
	});

	return (
		<input
			class={styles({
				size: props.size,
				color: props.variant,
				class: props.class || props.className,
				disabled: props.disabled
			})}
			ref={(ref) => {
				inputRef = ref;
				props.ref?.(ref)
			}}
			disabled={notNativeAttributes.disabled}
			{...others}
		/>
	);
};
