import { axiosClient } from "~/js/network/axiosClient";
import { BaseApi } from "../../api/baseApi";
import { type Accessor, type Setter, createContext, createSignal } from "solid-js";

/**
 * user personalized data based on setup account questionary
 */
export interface PersonalizedData {
	/**
	 * null if we dont need to show book a demo feature, else contains text for banner
	 */
	text_demo: string | null;
	/**
	 * flag controlls visibility of templates modal on application load
	 */
	isPersonalizedTemplatesVisible: boolean;
}

export interface PersonalizedDataDto {
	/**
	 * null if we dont need to show book a demo feature, else contains text for banner
	 */
	text_demo: string | null;
	/**
	 * flag controlls visibility of templates modal on application load
	 */
	template_show: boolean;
}

export class TemplateCategorySectionMapper {
	static toModel(
		dto: PersonalizedDataDto
	): PersonalizedData {
		return {
			text_demo: dto.text_demo,
			isPersonalizedTemplatesVisible: dto.template_show
		};
	}
}
export interface PersonalizedDataCtx {
	getPersonalizedData: Accessor<PersonalizedData>;
	setPersonalizedData: Setter<PersonalizedData>;
}

export class PersonalizedApi extends BaseApi {
	static async getPersonalizedData(): Promise<PersonalizedData> {
		const { data } = await axiosClient.axiosClient.get<PersonalizedDataDto>(
			`${this.baseUrl}v2/personalization/promo`
		);
		return TemplateCategorySectionMapper.toModel(data);
	}
}

const [getPersonalizedData, setPersonalizedData] = createSignal<PersonalizedData>({
	text_demo: null,
	isPersonalizedTemplatesVisible: false
});

export const getPersonalizedDataAndSetStore = async () => {
	return setPersonalizedData(await PersonalizedApi.getPersonalizedData());
};

export const shouldRenderBookDemo = (ctx: PersonalizedDataCtx) => {
	return !!ctx.getPersonalizedData().text_demo;
};

export const getRenderBookDemoText = (ctx: PersonalizedDataCtx) => {
	return ctx.getPersonalizedData().text_demo;
};

export const getIsPersonalizedTemplatesVisible = (ctx: PersonalizedDataCtx) => {
	return ctx.getPersonalizedData().isPersonalizedTemplatesVisible;
};

export const createContextValue = () => {
	return {
		getPersonalizedData,
		setPersonalizedData
	};
};

export const PersonalizedContext = createContext<PersonalizedDataCtx>(createContextValue());
