import type { Component } from "solid-js";
import { type ComponentProps, splitProps, Show, children } from "solid-js";
import classes from "./Toggle.module.scss";
import { Switch as ArkSwitch, type SwitchRootProps } from "@ark-ui/solid";
import { type VariantProps, tv } from "tailwind-variants";

export interface SwitchProps extends SwitchRootProps, SwitchVariantProps {}

export const Switch = (props: SwitchProps) => {
	const [variantProps, switchProps] = splitProps(props, ["size", "colorStyle", "class"]);
	const [localProps, rootProps] = splitProps(switchProps, ["children"]);
	// @ts-ignore
	const getChildren = children(() => localProps.children);
	const { root, control, label, thumb } = styles(variantProps);

	return (
		<ArkSwitch.Root class={root()} {...rootProps}>
			<ArkSwitch.Control class={control()}>
				<ArkSwitch.Thumb class={thumb()} />
			</ArkSwitch.Control>
			<Show when={getChildren()}>
				<ArkSwitch.Label class={label()}>{getChildren()}</ArkSwitch.Label>
			</Show>
		</ArkSwitch.Root>
	);
};

type SwitchVariantProps = VariantProps<typeof styles>;

const styles = tv({
	base: "switchRecipe",
	defaultVariants: { size: "md", colorStyle: "green" },
	slots: {
		root: "gap-2",
		label: "switchRecipe__label",
		control: "p-[1px] bg-black rounded-full cursor-pointer inline-flex items-center",
		thumb: "duration-200 transition-transform data-[state=checked]:translate-x-full  rounded-full bg-white"
	},
	variants: {
		colorStyle: {
			purple: {
				control: "bg-gray-400 data-[state=checked]:bg-interaction-violent"
			},
			green: {
			 control: "bg-gray-400 data-[state=checked]:bg-green-500"
			}
		},
		size: {
			sm: {
				control: "w-6",
				thumb: "size-3"
			},
			md: {
				control: "w-7",
				thumb: "size-3 data-[state=checked]:translate-x-3.5"
			},
			lg: {
				control: "w-6",
				thumb: "size-3"
			}
		}
	}
});

type ToggleProps = {} & ComponentProps<"input">;

export const Toggle: Component<ToggleProps> = (props) => {
	const [notNativeAttributes, others] = splitProps(props, ["children", "type"]);

	return (
		<div>
			<input
				type={"checkbox"}
				id={props.id || "defaultToggleId"}
				class={classes.input}
				data-checked={props.checked}
				hidden
				{...others}
			/>
			<label class={classes.label} for={props.id || "defaultToggleId"}>
				<div class={classes.toggler} />
			</label>
		</div>
	);
};
