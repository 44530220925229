import { Navigate, type RouteDefinition } from "@solidjs/router";
import { lazy } from "solid-js";
import { dataTrainNewModel } from "~/solidJs/pages/(TrainPanelHomeLayout)/api/dataTrainPanelSchemas";
import { SuspenseBlock } from "~/solidJs/shared/ui/atoms/SuspenseBlock/SuspenseBlock";
import { dataTrainPanelHomePage } from "~/solidJs/pages/TrainPanelHomePage/api/dataTrainPanelHomePage";
import { PrivateHoc } from "~/solidJs/shared/helpers/HOCS/PrivateHOC";
import { dataMagicCanvas } from "~/solidJs/pages/MagicCanvasPage/dataMagicCanvas";
import { verifyEmailDataFc } from "~/solidJs/pages/[VerifyEmailPage]/data";
import { thirdPartySignInAndUpData } from "~/solidJs/pages/[OauthCallbackPage]/data";
import { HomePageApi } from "~/solidJs/shared/api/HomePageApi";
import { IndexApi } from "~/solidJs/pages/Index/data";
import { Spinner } from "~/solidJs/shared/ui/atoms/Spinner/Spinner";
import SuccessfulPaymentData from "~/solidJs/pages/SuccessfulPayment/data";
import { IndexLayoutData } from "~/solidJs/pages/(IndexLayout)/data";
import type { IUser } from "~/solidJs/shared/helpers/types";
import { PersonalizedData } from "~/solidJs/shared/models/PersonalizeData";

type Route = {
	absolute: string;
	relative: string;
	params?: string;
};

export const routerConfig = {
	home: {
		absolute: "/home",
		relative: "/home"
	},
	workspace: {
		absolute: "/",
		relative: "/"
	},
	aiApps: {
		absolute: "/ai-apps",
		relative: "/ai-apps"
	},
	gallery: {
		absolute: "/home/gallery",
		relative: "/gallery"
	},
	signIn: {
		absolute: "/sign-in",
		relative: "/sign-in"
	},
	signUp: {
		absolute: "/sign-up",
		relative: "/sign-up"
	},
	templates: {
		absolute: "/home/templates",
		relative: "/templates"
	},
	userWorkspace: {
		absolute: "/user-workspace",
		relative: "/user-workspace",
		params: "/user-workspace/:id"
	},
	sharedWorkspace: {
		absolute: "/workspace",
		relative: "/workspace",
		params: "/workspace/:id"
	},
	templateWorkspace: {
		absolute: "/template",
		relative: "/template",
		params: "/template/:id"
	},
	taskWorkspace: {
		absolute: "/task",
		relative: "/task",
		params: "/task/:id"
	},
	homeProjects: {
		absolute: "/home/projects",
		relative: "/projects"
	},
	pricingPage: {
		absolute: "/pricing",
		relative: "/pricing"
	},
	account: {
		absolute: "/account",
		relative: "/account"
	},
	successfulPayment: {
		absolute: "/successful-payment",
		relative: "/successful-payment"
	},
	trainPanel: {
		absolute: "/train-panel",
		relative: "/train-panel"
	},
	trainNewModel: {
		absolute: "/train-panel/train-new-model",
		relative: "/train-new-model"
	},
	setupAccount: {
		absolute: "/setup-account",
		relative: "/setup-account"
	},
	verifyEmailPage: {
		absolute: "/verify-email",
		relative: "/verify-email"
	}
} as const satisfies Record<string, Route>;

export const accessRedirectMap: Record<string, string> = {
	[routerConfig.trainNewModel.absolute]: routerConfig.trainPanel.absolute
};

export const oldRedirectMap: Record<string, string> = {
	[routerConfig.signUp.absolute]: routerConfig.signIn.absolute
};

export const routerRedirectsConfig = {
	afterAuth: routerConfig.workspace.absolute
} as const;
Object.freeze(routerConfig);
export const ignorePreventSave = { [routerConfig.signIn.absolute]: true };

export type AuthRouterContext = {
	user: Promise<IUser>;
};

const needsAccountSetupRoutes: RouteDefinition[] = [
	{
		path: [
			routerConfig.workspace.absolute,
			routerConfig.userWorkspace.params,
			routerConfig.sharedWorkspace.params,
			routerConfig.templateWorkspace.params,
			routerConfig.taskWorkspace.params,
			routerConfig.pricingPage.absolute
		],
		component: () => lazy(() => import("~/solidJs/pages/Index"))({}),
		children: [],
		data: IndexApi.loadIndexData.bind(IndexApi)
	},
	{
		path: routerConfig.successfulPayment.absolute,
		component: lazy(() => import("~/solidJs/pages/SuccessfulPayment")),
		data: SuccessfulPaymentData
	},
	{
		path: "/",
		component: lazy(() => import("~/solidJs/pages/(TrainPanelLayout)")),
		children: [
			{
				path: "/",
				component: lazy(() => import("~/solidJs/pages/(HomePageLayout)")),
				children: [
					{
						path: [
							routerConfig.home.absolute,
							routerConfig.templates.absolute,
							routerConfig.gallery.absolute,
							routerConfig.homeProjects.absolute
						],
						component: lazy(() => import("~/solidJs/pages/HomePage")),
						data: HomePageApi.loadHomePageData
					},
					{
						path: routerConfig.aiApps.absolute,
						component: lazy(() => import("~/solidJs/pages/AiAppsPage/AiAppsPage"))
					}
				]
			},
			{
				path: routerConfig.account.absolute,
				component: lazy(() => import("~/solidJs/pages/AccountPage")),
				children: [
					{
						path: ["/"],
						component: lazy(() => import("~/solidJs/pages/SubscriptionPage"))
					}
				]
			},
			{
				path: "/canvas",
				component: lazy(() => import("~/solidJs/pages/MagicCanvasPage")),
				data: dataMagicCanvas
			},
			{
				path: "/train-panel/canvas",
				component: () => <Navigate href={"/canvas"} />
			},
			{
				path: routerConfig.trainPanel.absolute,
				component: lazy(
					() => import("~/solidJs/pages/(TrainPanelHomeLayout)/(TrainPanelHomeLayout)")
				),
				data: dataTrainNewModel,
				children: [
					{
						path: "/",
						component: lazy(() => import("~/solidJs/pages/TrainPanelHomePage")),
						//@ts-ignore
						data: dataTrainPanelHomePage
					},
					{
						path: routerConfig.trainNewModel.relative,
						component: () => (
							<PrivateHoc
								accessMap={{
									premium: true,
									superuser: true,
									enterprise: true,
									plus: true
								}}
							>
								{lazy(() => import("~/solidJs/pages/TrainPanelTrainNewModelPage"))(
									{}
								)}
							</PrivateHoc>
						)
					}
				]
			}
		]
	}
];
export const routes: RouteDefinition[] = [
	{
		path: "*404",
		component: lazy(() => import("~/solidJs/pages/404"))
	},
	{
		path: "/",
		component: lazy(() => import("~/solidJs/pages/(IndexLayout)")),
		data: IndexLayoutData,
		children: [
			{
				path: routerConfig.setupAccount.absolute,
				component: () => lazy(() => import("~/solidJs/pages/SetupAccountPage"))({})
			},
			{
				path: "/",
				component: lazy(() => import("~/solidJs/pages/(NeedsStupLayout)")),
				children: needsAccountSetupRoutes,
				data: (props) => {
					PersonalizedData.getPersonalizedDataAndSetStore();
					return props.data
				}
			}
		]
	},
	{
		path: routerConfig.verifyEmailPage.absolute,
		component: lazy(() => import("~/solidJs/pages/VerifyEmailPage/VerifyEmailPage"))
	},
	{
		path: "/",
		component: () => (
			<SuspenseBlock size="large">
				{lazy(() => import("~/solidJs/pages/(AuthLayout)"))({})}
			</SuspenseBlock>
		),
		children: [
			{
				path: routerConfig.signIn.absolute,
				component: () => lazy(() => import("~/solidJs/pages/SignInPage/SignInPage"))({})
			},
			{
				path: "/reset-password",
				component: () =>
					lazy(() => import("~/solidJs/pages/ResetPasswordPage/ResetPasswordPage"))({})
			},
			{
				path: "/auth/reset-password",
				component: () =>
					lazy(
						() =>
							import(
								"~/solidJs/pages/SubmitResetPasswordPage/SubmitResetPasswordPage"
							)
					)({})
			},
			{
				path: "/auth/callback",
				component: () => <Spinner size="large" />,
				data: thirdPartySignInAndUpData
			},
			{
				path: "/auth/verify-email",
				component: () => null,
				data: verifyEmailDataFc
			}
		]
	}
];
